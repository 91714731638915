
import _ from 'lodash';

import { Action } from '../api/src/models/Action';

import chartsMixin from '../mixins/charts';
import uiMixin from '../mixins/ui';

export default {
  name: 'InsightView',
  mixins: [
    chartsMixin,
    uiMixin,
  ],
  data() {
    return {
      layout: 'list',
      dayIndex: 0,
      interestsSelected: [],
    };
  },
  computed: {
    items() {
      let items = [];

      if (this.dayIndex < 0) {
        items = this.$store.getters['actionsUser/itemsCompletedOnVirtualDate'](this.day);
      } else if (this.dayIndex === 0) {
        items = this.$store.getters['actionsPlan/items'].map(i => this.getItemProps(i));
      } else {
        items = this.$store.getters['actionsUser/itemsExpiringOnVirtualDate'](this.day)
          .filter((i) => {
            const isRepeating = Action.isFrequencyRepeating(i.frequency);
            const includedInActionsPlan = !isRepeating && this.$store.getters['actionsPlan/itemIds'].includes(i.id);
            return !includedInActionsPlan && (!isRepeating || i.importance === Action.EffortImportance.ImportanceMaxValue);
          });
      }

      if (items.length > 0 && this.interestsSelected.length > 0) {
        items = items.filter(i => _.intersection(i.interest, this.interestsSelected).length > 0);
      }

      return this.getItemsOrdered(items);
    },
    valuePotential() {
      let val = 0;

      if (this.interestsSelected.length < 1 && this.dayIndex === 0) {
        val = this.$store.getters['actionsPlan/valueExpectedAdjusted'];
      } else {
        this.items.forEach((i) => {
          val = val + this.$store.getters.getValuePotentialForItem(i);
        });
      }

      return val;
    },
    valueTotal() {
      let val = 0;

      if (this.interestsSelected.length < 1) {
        if (this.dayIndex === 0) {
          val = this.$store.getters['actionsUser/valueTotalVirtualDay'];
        } else if (this.dayIndex < 0) {
          val = this.$store.getters['actionsUser/valueTotalForVirtualDate'](this.day);
        }
      } else {
        this.items.forEach((i) => {
          val = val + (typeof i.value === 'number' ? i.value : 0);
        });
      }

      return val;
    },
    isValueIncomplete() {
      return this.dayIndex > 0 || (this.dayIndex === 0 && this.valuePotential !== this.valueTotal);
    },
    day() {
      return this.$store.getters.virtualDay.add(this.dayIndex, 'days');
    },
    dayLabel() {
      let str;

      switch (this.dayIndex) {
        case 0:
          str = 'Today';
          break;
        case -1:
          str = 'Yesterday';
          break;
        case 1:
          str = 'Tomorrow';
          break;
        default:
          str = this.dayIndex > 0 ? `${this.dayIndex} days from now` : `${Math.abs(this.dayIndex)} days ago`;
      }

      return str;
    },
    interestsOptions() {
      const selectedInterests = this.$store.state.interests.all
        .filter(i => this.interestsSelected.includes(i.id))
        .map(i => ({
          label: i.name,
          id: i.id,
        }));

      const otherInterests = this.$store.state.interests.all
        .filter(i => !this.interestsSelected.includes(i.id))
        .map(i => ({
          label: i.name,
          id: i.id,
        }));

      // sort by name
      otherInterests.sort(function(a, b) {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      return selectedInterests.concat(otherInterests);
    },
    canGoPrevious() {
      return this.dayIndex > (this.$store.state.recentDaysCount * -1);
    },
    canGoToday() {
      return this.dayIndex !== 0;
    },
    canGoNext() {
      return this.dayIndex < this.$store.state.lookaheadDaysCount;
    },
    summary() {
      return this.items.map(i => i.description).join('\n');
    },
  },
  methods: {
    getItemProps(i) {
      return {
        ...i,
        // forces repeating actions to 'Done'
        status: this.$store.getters['actionsUser/isItemComplete'](i) ? Action.Status.enum.Done : Action.Status.enum.Todo,
        lastProgressAt: Action.isFrequencyRepeating(i.frequency) ? this.$store.getters['actionsUser/getRepeatingItemLastProgress'](i) : (i.lastProgressAt || 0),
      };
    },
    getItemsOrdered(items) {
      return _.orderBy(items, ['status', 'lastProgressAt', 'importance'], ['desc', 'desc', 'desc']);
    },
    getLastProgressAtDisplay(lastProgressAt) {
      return lastProgressAt ? this.$dayjs(lastProgressAt).format('hh:mm a') : '';
    },
    getLinkDisplay(l) {
      if (typeof l === 'string' && l.length >= 70) {
        return `${l.substring(0, 70)}...`;
      } else {
        return l;
      }
    },
    handlePrevious() {
      if (this.canGoPrevious) {
        this.dayIndex = this.dayIndex - 1;
      }
    },
    handleToday() {
      if (this.canGoToday) {
        this.dayIndex = 0;
      }
    },
    handleNext() {
      if (this.canGoNext) {
        this.dayIndex = this.dayIndex + 1;
      }
    },
    handleInterestsSelected(e) {
      if (e) {
        const { value } = e.target;

        if (Array.isArray(value)) {
          this.interestsSelected = [...value];
        } else {
          this.interestsSelected = [];
        }
      }
    },
    handleCopySummary() {
      this.uiCopyToClipboard(this.summary);
    },
    handleActionSelected(items, actionId) {
      // pass all items and set activeIndex to actionId index
      this.uiShowActionSelector(items.map(i => i.id), actionId);
    },
  },
};
