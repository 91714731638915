import { render, staticRenderFns } from "./insight.vue?vue&type=template&id=4a08f9ae&"
import script from "./insight.vue?vue&type=script&lang=js&"
export * from "./insight.vue?vue&type=script&lang=js&"
import style0 from "./insight.vue?vue&type=style&index=0&id=4a08f9ae&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChartLine: require('/opt/build/repo/components/chart/chart-line.vue').default,Value: require('/opt/build/repo/components/value.vue').default,ChartDoughnut: require('/opt/build/repo/components/chart/chart-doughnut.vue').default,ActionsDataView: require('/opt/build/repo/components/actions-data-view.vue').default})
